<template>
  <div class="jexy-file-input" >
    <!-- TODO This should be better... -->
    <label class="v-label theme--light" style="font-size: 13px;" v-html="control.label" />
    <!-- Image Preview -->
    <j-graphic
      :src="inputPreview"
      :contain="true"
      class="image-preview mt-2"
      :round="round"
      @mouseover.native="showButtons"
      @mouseleave.native="hideButtons"
      :max-height="round ? null : 200"
    >
      <v-row
        class="lightbox white--text fill-height"
        align="center"
      >
        <v-col align="center">
          <v-btn-toggle v-show="displayButtons" dense rounded>

            <!-- Upload Button -->
            <v-btn @click="handleUpload">
              <j-icon icon="upload" color="primary" />
            </v-btn>

            <!-- Crop Button -->
            <v-btn v-if="showCropButton" @click="handleCrop">
              <j-icon icon="crop" color="primary" />
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>
    </j-graphic>

    <!-- Cropper -->
    <ImageCropper />

  </div>
</template>

<script>

// Mixin
import Control from './Mixin'

// Cropper
import ImageCropper from '@/components/Media/Images/ImageCropper'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapMutations } = createNamespacedHelpers('media')

export default {
  name: 'Cdn',
  mixins: [ Control ],
  components: {
    ImageCropper
  },
  props: {
    originalSrc: {
      type: String,
      default: '/assets/img/missing-image.png'
    },
    round: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default: () => {}
    },
    processor: {
      type: Function
    },
    cropSettings: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      displayButtons: false
    }
  },
  computed: {
    inputPreview () {
      if (!this.control.model) return null

      // Is there a cropping preview?
      let src = this.control.model.get(`${this.name}_preview`)
      if (src) {
        return src
      }
      // What's our source?
      src = this.control.model.get(this.name)
      if (src?.startsWith('image/upload')) {
        return `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/${src}`
      } else if (src) {
        return src
      } else {
        return this.originalSrc
      }
    },
    showCropButton () {
      return this.control.localCropping
    }
  },
  methods: {
    ...mapActions([
      'openCropper',
      'openUploader'
    ]),
    ...mapMutations([
      'CROP_SETTINGS'
    ]),
    showButtons () {
      this.displayButtons = true
    },
    hideButtons () {
      this.displayButtons = false
    },
    handleUpload () {
      this.openUploader({
        ...this.control.options,
        ...this.metadata,
        processor: this.processor || this.updateModel
      })
    },
    handleCrop () {
      this.openCropper()
    },
    updateModel (upload) {
      console.log('updateModel', this.name, upload.cdn_src)
      this.control.model.set(this.name, upload.cdn_src)
    }
  },
  watch: {
    cropSettings: {
      handler(settings) { this.CROP_SETTINGS(settings) },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

.image-preview {
  cursor: pointer;
}

</style>