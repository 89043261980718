<template>

  <!-- Image Cropper -->
  <j-dialog
    dialog-name="cropper"
    :value="$dialog.status('cropper')"
    :title="`Cropping ${image.title}`"
    :persistent="false"
    class="image-cropper"
  >
    <!-- Preview -->
    <v-sheet
      v-show="previewMode"
      :width="previewFrameWidth"
      :height="previewFrameHeight"
      class="cropping-preview"
    >
      <v-row
        class="fill-height"
        align="center"
      >
        <v-col align="center" class="pa-0">
          <j-graphic
            :src="previewImage"
            :contain="true"
            :width="previewImageWidth"
            :height="previewImageHeight"
            class="preview-image"
          >
            <j-label :title="finalDimensions" small solid color="primary" />
          </j-graphic>
        </v-col>
      </v-row>
    </v-sheet>
    <!-- Cropper -->
    <vue-cropper
      v-if="!previewMode"
      ref="cropper"
      :src="image.src.original"
      :alt="image.title"
      :view-mode="1"
      :data="cropping"
      :img-style="{ 'max-height': dialogHeight }"
      drag-mode="none"
      :auto-crop-area="1"
      @ready="readyCropper"
      @cropend="updateCropper"
      @zoom="updateCropper"
    />
    <!-- Cropping Controls -->
    <v-container :fluid="true">
    <v-row>
      <v-col class="d-flex justify-space-between">
        <!-- Aspect Ratio -->
        <v-btn-toggle
          v-model="controls.aspectRatioName"
          dense mandatory
          class="ml-2"
          @change="updateCroppingData"
        >
          <v-btn :disabled="disableAspectRatio" value="free">Free</v-btn>
          <v-btn :disabled="disableAspectRatio" value="widescreen">16:9</v-btn>
          <v-btn :disabled="disableAspectRatio" value="landscape">4:3</v-btn>
          <v-btn :disabled="disableAspectRatio" value="square">Square</v-btn>
          <v-btn :disabled="disableAspectRatio" value="portrait">3:4</v-btn>
        </v-btn-toggle>
        <!-- Rotation -->
        <!-- <v-btn :disabled="disableRotation" @click="updateRotation(-1)">
          <j-icon icon="undo" />
        </v-btn>
        <v-btn :disabled="disableRotation" @click="updateRotation(0)">
          {{ cropping.rotate }}
        </v-btn>
        <v-btn :disabled="disableRotation" @click="updateRotation(1)">
          <j-icon icon="redo" />
        </v-btn> -->
        <!-- Set Width -->
        <v-btn-toggle
          v-model="controls.fixedWidth"
          dense mandatory
          @change="updateCropper"
        >
          <v-btn :disabled="disableFixedWidth" :value="0">Cropped</v-btn>
          <v-btn :disabled="disableFixedWidth" :value="1200">1200px</v-btn>
          <v-btn :disabled="disableFixedWidth" :value="800">800px</v-btn>
          <v-btn :disabled="disableFixedWidth" :value="500">500px</v-btn>
        </v-btn-toggle>
        <!-- Final Dimensions -->
        <j-label
          :icon="finalIcon"
          :title="dimensions"
          class="mr-2"
          @click="toggleDimensions"
        />
      </v-col>
    </v-row>
    </v-container>

    <!-- {{ cropping }} -->
    <!-- {{ controls }} -->
    <!-- [ {{ aspectRatioNameLock }} / {{ fixedWidthLock }} ] -->

    <!-- Actions -->
    <template #actions>

      <!-- Crop -->
      <j-button
        v-if="previewMode"
        icon="crop"
        label="Crop"
        :processing="image.saving"
        @action="handleCrop"
      />

      <!-- Preview -->
      <j-button
        v-else
        icon="eye"
        label="Preview"
        @action="handlePreview"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!image.saving"
        label="Cancel"
        type="option"
        @action="handleCancel"
      />
    </template>
  </j-dialog>
</template>

<script>

// Cropper
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('media')

export default {
  name: 'ImageCropper',
  // -------------
  // Components ==
  // -------------
  components: {
    VueCropper
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      previewMode: false,
      previewImage: null,
      previewFrameWidth: 0,
      previewFrameHeight: 0,
      showFinalDimensions: true
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'displayCropper',
      'aspectRatioNameLock',
      'fixedWidthLock',
      'image',
      'controls',
      'cropping'
    ]),
    dialogHeight () {
      return `${window.innerHeight - 300}px`
    },
    finalScaling () {
      if (this.controls.fixedWidth) {
        return this.controls.fixedWidth / this.cropping.width
      } else {
        return 1
      }
    },
    finalWidth () {
      return this.controls.fixedWidth || this.cropping.width
    },
    finalHeight () {
      return Math.round(this.cropping.height * this.finalScaling)
    },
    disableAspectRatio () {
      return this.previewMode || !!this.aspectRatioNameLock
    },
    disableRotation () {
      return this.previewMode
    },
    disableFixedWidth () {
      return this.previewMode || !!this.fixedWidthLock
    },
    previewImageWidth () {
      return Math.min(this.finalWidth, this.previewFrameWidth)
    },
    previewImageHeight () {
      return Math.min(this.finalHeight, this.previewFrameHeight)
    },
    finalIcon () {
      if (this.finalScaling < 1.1) {
        return ''
      } else if (this.finalScaling < 1.25) {
        return 'exclamation-triangle'
      } else {
        return 'bomb'
      }
    },
    originalDimensions () {
      return `${this.image.original_width}px &times; ${this.image.original_height}px`
    },
    finalDimensions () {
      const percent = Math.floor(this.finalScaling * 100)
      return `${percent}% &bull; ${this.finalWidth}px &times; ${this.finalHeight}px`
    },
    dimensions () {
      return this.showFinalDimensions ? this.finalDimensions : this.originalDimensions
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'initCropper',
      'closeCropper',
      'updateCroppingData',
      'updateCropper'
    ]),
    toggleDimensions () {
      this.$refs.cropper.moveTo(100, 100)
      this.showFinalDimensions = !this.showFinalDimensions
    },
    readyCropper () {
      this.initCropper(this.$refs.cropper)
      this.updateCropper()
    },
    // updateRotation (direction) {
    //   const currentRotation = parseInt(this.cropping.rotate)
    //   const degrees = event.shiftKey ? 10 : 1
    //   let newRotation = 0
    //   if (direction) {
    //     newRotation = currentRotation + (degrees * direction)
    //   }
    //   this.$refs.cropper.rotateTo(newRotation)
    //   this.updateCropper()
    // },
    handleCancel () {
      if (this.previewMode) {
        this.previewMode = false
      } else {
        this.image.reset('featured')
        this.closeCropper()
      }
    },
    handlePreview () {
      const container = this.$refs.cropper.getContainerData()
      this.previewFrameWidth = container.width
      this.previewFrameHeight = container.height
      this.previewImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.previewMode = true
    },
    handleCrop () {
      this.previewMode = false
      this.image.set({ cropping: {
        ...this.cropping,
        finalWidth: this.finalWidth,
        finalHeight: this.finalHeight
      }})
      this.image.set('cdn_src_preview', this.previewImage)
      this.closeCropper()
    }
  }
}
</script>

<style lang="scss">

.cropping-preview {
  @extend %j-background-screen;
}
.cropper-bg {
  background-repeat: repeat;
}

</style>
